var render = function () {
  var _vm$instrument$images;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "instrument-wrapper"
  }, [_c('div', {
    staticClass: "instrument-id"
  }, [_vm._v(_vm._s(_vm.instrument.slug))]), _c('router-link', {
    staticClass: "instrument-image-link",
    attrs: {
      "to": _vm.link
    }
  }, [_c('img', {
    staticClass: "instrument-img",
    attrs: {
      "alt": _vm.instrument.title || _vm.instrument.name,
      "src": ((_vm$instrument$images = _vm.instrument.images) === null || _vm$instrument$images === void 0 ? void 0 : _vm$instrument$images[0]) || _vm.defaultImage
    },
    on: {
      "click": function click($event) {
        return _vm.changeCurrentItem({
          images: _vm.instrument.images,
          description: _vm.instrument.title
        });
      }
    }
  })]), _c('div', {
    staticClass: "instrument-name",
    attrs: {
      "title": _vm.instrument.title || _vm.instrument.name
    }
  }, [_c('span', {
    staticClass: "bottom"
  }, [_vm._v(_vm._s(_vm.instrument.title || _vm.instrument.name))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }