var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fake-content"
  }, [_c('discussion-form', {
    attrs: {
      "breadcrumbs": [{
        label: _vm.getTranslate.forum.forum,
        route: _vm.Routes.ForumTopicList
      }, {
        label: _vm.getTranslate.forum.createTopic,
        route: ''
      }],
      "loading": _vm.loading,
      "description": _vm.description,
      "title": _vm.title,
      "button-text": _vm.getTranslate.forum.createTopic,
      "permissions": _vm.permissions.createDiscussions
    },
    on: {
      "change-title": function changeTitle(value) {
        return _this.title = value;
      },
      "change-description": function changeDescription(value) {
        return _this.description = value;
      },
      "submit": _vm.handleSubmit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }