var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.getTranslate.common ? _c('div', {
    staticClass: "footer page-content"
  }, [_c('address', {
    staticClass: "top-wrapper"
  }, [_c('a', {
    staticClass: "main-title footer__info-text",
    attrs: {
      "target": "_blank",
      "href": "mailto:".concat(_vm.getTranslate.common.info)
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.common.info))]), _c('span', {
    staticClass: "main-title footer__info-text right"
  }, [_vm._v(_vm._s(_vm.getTranslate.common.adress))])]), _c('broken-line', {
    attrs: {
      "rtl": "",
      "className": "desktop__line"
    }
  }), _c('address', {
    staticClass: "bot-wrapper"
  }, [_c('div', {
    staticClass: "footer__info-text"
  }, [_c('span', {
    staticClass: "main-title footer__info-text"
  }, [_vm._v(_vm._s(_vm.getTranslate.common.faxLabel) + _vm._s(': '))]), _c('a', {
    staticClass: "main-title footer__info-text",
    attrs: {
      "href": "tel:".concat(_vm.getTranslate.common.fax)
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.common.fax))])])])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }