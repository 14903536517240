var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-page"
  }, [_c('spinner-container', {
    attrs: {
      "loading": _vm.getSearch.loading
    }
  }, [_c('div', {
    staticClass: "search-page__section"
  }, [_c('p', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.common.searchPhrase) + " "), _c('b', [_vm._v(_vm._s(_vm.value))])]), _vm.getSearch.result.length ? _c('div', [_c('section', _vm._l(_vm.getSearch.result, function (item) {
    return _c('a', {
      key: item.url,
      staticClass: "search-item",
      attrs: {
        "href": item.url
      }
    }, [_c('span', {
      staticClass: "search-item__title",
      attrs: {
        "title": item.title
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "search-item__description",
      domProps: {
        "innerHTML": _vm._s(item.snippet)
      }
    })]);
  }), 0), _c('pagination', {
    attrs: {
      "per-page": 20,
      "records": _vm.getSearch.meta.total || 0
    },
    on: {
      "paginate": _vm.handlePaginate
    },
    model: {
      value: _vm.getSearch.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.getSearch.meta, "current_page", $$v);
      },
      expression: "getSearch.meta.current_page"
    }
  })], 1) : _c('h1', {
    staticClass: "search-page__empty-search"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.search.emptyResult) + " ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }