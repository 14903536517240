var render = function () {
  var _vm$getDiscussion$dis, _vm$getDiscussion$dis2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !!_vm.getTranslate.common ? _c('div', {
    staticClass: "view-topic"
  }, [!!((_vm$getDiscussion$dis = _vm.getDiscussion.discussion) !== null && _vm$getDiscussion$dis !== void 0 && _vm$getDiscussion$dis.id) ? _c('div', [_c('Breadcrumbs', {
    attrs: {
      "itemList": [{
        label: _vm.getTranslate.forum.forum,
        route: _vm.Routes.ForumTopicList
      }, {
        label: _vm.getDiscussion.discussion.title
      }],
      "wrapperClass": "topic-list__breadcrumbs"
    }
  }), _c('h1', {
    staticClass: "main-title m-t_1 view-topic__titles"
  }, [_vm._v(_vm._s(_vm.getDiscussion.discussion.title))]), _c('h4', {
    staticClass: "sub-title m-t_1 view-topic__titles"
  }, [_vm._v(_vm._s(_vm.getDiscussion.discussion.description))]), !!((_vm$getDiscussion$dis2 = _vm.getDiscussion.discussion) !== null && _vm$getDiscussion$dis2 !== void 0 && _vm$getDiscussion$dis2.id) ? _c('spinner-container', {
    attrs: {
      "loading": _vm.getPostList.loading || _vm.getPost.deletePostLoading
    }
  }, [_c('div', {
    staticClass: "view-topic__content"
  }, [_c('div', {
    staticClass: "view-topic__row"
  }, [_vm.getPostList && _vm.getPostList.list && _vm.getPostList.list.length ? _c('div', {
    staticClass: "simple"
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.title))]) : _c('div', {
    staticClass: "simple"
  }), _vm.permissions.createPosts ? _c('button', {
    staticClass: "forum-button view-topic__create-button",
    on: {
      "click": _vm.onCreatePost
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.createPost))]) : _vm._e()]), _vm.getPostList && _vm.getPostList.list && _vm.getPostList.list.length ? _c('div', [_vm._l(_vm.getPostList.list, function (post) {
    return _c('div', {
      key: post.id,
      staticClass: "view-topic__post"
    }, [_c('div', {
      staticClass: "view-topic__post-row"
    }, [_c('div', {
      staticClass: "view-topic__td"
    }, [_c('router-link', {
      staticClass: "view-topic__link",
      attrs: {
        "to": {
          name: _vm.RouterName.ForumPostView,
          params: {
            topicId: post.discussion.id,
            postId: post.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(post.title) + " ")])], 1), _c('svg', {
      class: 'view-topic__post-icon view-topic__td',
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "viewBox": "0 0 24 24",
        "svg-inline": '',
        "src": '@/shared/Images/Forum/comment.svg'
      }
    }, [_c('path', {
      attrs: {
        "d": "M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z",
        "fill": "currentColor"
      }
    })]), _c('svg', {
      class: 'view-topic__post-icon view-topic__td',
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "viewBox": "0 0 24 24",
        "svg-inline": '',
        "src": '@/shared/Images/Forum/heart.svg'
      }
    }, [_c('path', {
      attrs: {
        "d": "M12 21.593C6.37 16.054 1 11.296 1 7.191 1 3.4 4.068 2 6.281 2c1.312 0 4.151.501 5.719 4.457 1.59-3.968 4.464-4.447 5.726-4.447C20.266 2.01 23 3.631 23 7.191c0 4.069-5.136 8.625-11 14.402M17.726 1.01c-2.203 0-4.446 1.042-5.726 3.238C10.715 2.042 8.478 1 6.281 1 3.098 1 0 3.187 0 7.191 0 11.852 5.571 16.62 12 23c6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181",
        "fill": "currentColor"
      }
    })]), _c('svg', {
      class: 'view-topic__post-icon view-topic__td',
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "height": "24",
        "width": "24",
        "viewBox": "0 0 511.999 511.999",
        "svg-inline": '',
        "src": '@/shared/Images/Forum/eye.svg'
      }
    }, [_c('path', {
      attrs: {
        "d": "M508.129 245.942c-1.155-1.278-28.809-31.661-73.431-62.307C375.034 142.659 313.241 121 255.999 121c-57.241 0-119.034 21.659-178.698 62.635-44.622 30.646-72.275 61.029-73.43 62.307-5.162 5.712-5.162 14.404 0 20.115 1.155 1.278 28.809 31.661 73.431 62.307C136.965 369.341 198.759 391 255.999 391c57.241 0 119.034-21.659 178.698-62.635 44.622-30.646 72.275-61.029 73.431-62.307 5.161-5.712 5.161-14.404.001-20.116zM145.484 333.44c-40.404-19.392-78.937-47.81-109.449-77.434 17.675-17.131 57.684-52.53 109.471-77.478C130.074 200.472 121 227.195 121 256c-.001 28.791 9.064 55.502 24.484 77.44zM255.999 361c-57.848 0-105-47.06-105-105 0-57.897 47.103-105 105-105 57.854 0 105 47.066 105 105 .001 57.897-47.102 105-105 105zm110.495-27.528C381.926 311.528 391 284.805 391 256c0-28.791-9.065-55.502-24.484-77.44 40.404 19.392 78.937 47.81 109.449 77.434-17.674 17.131-57.684 52.53-109.471 77.478z",
        "fill": "currentColor"
      }
    }), _c('path', {
      attrs: {
        "d": "M255.999 181c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75c.001-41.355-33.644-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.186 45-45 45z",
        "fill": "currentColor"
      }
    })]), _c('div', {
      staticClass: "view-topic__activity view-topic__td"
    }, [_vm._v(_vm._s(_vm.getTranslate.forum.activity))]), _vm.showPostOptions ? _c('div', {
      staticClass: "view-topic__td"
    }, [_c('dropdown', [_c('p', [_vm.canEdit ? _c('router-link', {
      staticClass: "post-dropdown__item m-b_1",
      attrs: {
        "to": {
          name: _vm.RouterName.ForumPostEdit,
          params: {
            topicId: post.discussion.id,
            postId: post.id
          }
        }
      }
    }, [_c('svg', {
      class: 'post-dropdown__icon',
      attrs: {
        "height": "484pt",
        "viewBox": "-15 -15 484 484",
        "width": "484pt",
        "xmlns": "http://www.w3.org/2000/svg",
        "svg-inline": '',
        "src": '@/shared/Images/Forum/pen.svg'
      }
    }, [_c('path', {
      attrs: {
        "d": "M401.648 18.234c-24.394-24.351-63.898-24.351-88.293 0l-22.101 22.223-235.27 235.145-.5.503c-.12.122-.12.25-.25.25-.25.375-.625.747-.87 1.122 0 .125-.13.125-.13.25-.25.375-.37.625-.625 1-.12.125-.12.246-.246.375-.125.375-.25.625-.379 1 0 .12-.12.12-.12.25L.663 437.32a12.288 12.288 0 002.996 12.735 12.564 12.564 0 008.867 3.625c1.356-.024 2.7-.235 3.996-.625l156.848-52.325c.121 0 .121 0 .25-.12a4.523 4.523 0 001.121-.505.443.443 0 00.254-.12c.371-.25.871-.505 1.246-.755.371-.246.75-.62 1.125-.87.125-.13.246-.13.246-.25.13-.126.38-.247.504-.5l257.371-257.372c24.352-24.394 24.352-63.898 0-88.289zM169.375 371.383l-86.914-86.91L299.996 66.938l86.914 86.91zm-99.156-63.809l75.93 75.926-114.016 37.96zm347.664-184.82l-13.238 13.363L317.727 49.2l13.367-13.36c14.62-14.609 38.32-14.609 52.945 0l33.965 33.966c14.512 14.687 14.457 38.332-.121 52.949zm0 0",
        "fill": "currentColor"
      }
    })]), _vm._v(" " + _vm._s(_vm.getTranslate.forum.editPost) + " ")]) : _vm._e()], 1), _c('p', [_vm.canDelete ? _c('button', {
      staticClass: "post-dropdown__item prevent-button-styles",
      on: {
        "click": function click($event) {
          return _vm.handleDeletePost(post.id);
        }
      }
    }, [_c('svg', {
      class: 'post-dropdown__icon',
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "viewBox": "0 0 486.4 486.4",
        "svg-inline": '',
        "src": '@/shared/Images/Forum/basket.svg'
      }
    }, [_c('g', {
      attrs: {
        "fill": "currentColor"
      }
    }, [_c('path', {
      attrs: {
        "d": "M446 70H344.8V53.5c0-29.5-24-53.5-53.5-53.5h-96.2c-29.5 0-53.5 24-53.5 53.5V70H40.4c-7.5 0-13.5 6-13.5 13.5S32.9 97 40.4 97h24.4v317.2c0 39.8 32.4 72.2 72.2 72.2h212.4c39.8 0 72.2-32.4 72.2-72.2V97H446c7.5 0 13.5-6 13.5-13.5S453.5 70 446 70zM168.6 53.5c0-14.6 11.9-26.5 26.5-26.5h96.2c14.6 0 26.5 11.9 26.5 26.5V70H168.6V53.5zm226 360.7c0 24.9-20.3 45.2-45.2 45.2H137c-24.9 0-45.2-20.3-45.2-45.2V97h302.9v317.2h-.1z"
      }
    }), _c('path', {
      attrs: {
        "d": "M243.2 411c7.5 0 13.5-6 13.5-13.5V158.9c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v238.5c0 7.5 6 13.6 13.5 13.6zM155.1 396.1c7.5 0 13.5-6 13.5-13.5V173.7c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v208.9c0 7.5 6.1 13.5 13.5 13.5zM331.3 396.1c7.5 0 13.5-6 13.5-13.5V173.7c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v208.9c0 7.5 6 13.5 13.5 13.5z"
      }
    })])]), _vm._v(" " + _vm._s(_vm.getTranslate.forum.deletePost) + " ")]) : _vm._e()])])], 1) : _vm._e()]), _c('div', {
      staticClass: "view-topic__post-row"
    }, [_c('div', {
      staticClass: "view-topic__td view-topic__author"
    }, [_vm._v(_vm._s(_vm.getTranslate.forum.author) + ": " + _vm._s(post.authorName || _vm.getTranslate.forum.unknownAuthor))]), _c('div', {
      staticClass: "view-topic__td"
    }, [_vm._v(_vm._s(post.comments))]), _c('div', {
      staticClass: "view-topic__td"
    }, [_vm._v(_vm._s(post.likes))]), _c('div', {
      staticClass: "view-topic__td"
    }, [_vm._v(_vm._s(post.views))]), _c('div', {
      staticClass: "view-topic__td"
    }, [_vm._v(_vm._s(_vm.dayjs(post.updated_at).format('MMMM')))]), _vm.showPostOptions ? _c('div', {
      staticClass: "view-topic__td"
    }) : _vm._e()])]);
  }), _c('pagination', {
    attrs: {
      "per-page": 20,
      "records": _vm.paginationRecords
    },
    on: {
      "paginate": _vm.handlePaginate
    },
    model: {
      value: _vm.paginationModel,
      callback: function callback($$v) {
        _vm.paginationModel = $$v;
      },
      expression: "paginationModel"
    }
  })], 2) : _vm._e()])]) : _vm._e()], 1) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }