var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "new-password__title"
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.newPasswordTitle))]), _c('ValidationObserver', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('form', {
          staticClass: "new-password__form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.handleSubmit.apply(null, arguments);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }],
                staticClass: "new-password__input",
                class: {
                  'new-password__input_error': errors[0]
                },
                attrs: {
                  "disabled": _vm.submitting,
                  "type": "password",
                  "placeholder": _vm.getTranslate.forum.pass
                },
                domProps: {
                  "value": _vm.password
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }
                    _vm.password = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": "passwordConfirmation",
            "rules": "required|password:@password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.passwordConfirmation,
                  expression: "passwordConfirmation"
                }],
                staticClass: "new-password__input",
                class: {
                  'new-password__input_error': errors[0]
                },
                attrs: {
                  "disabled": _vm.submitting,
                  "type": "password",
                  "placeholder": _vm.getTranslate.forum.passwordConfirmation
                },
                domProps: {
                  "value": _vm.passwordConfirmation
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }
                    _vm.passwordConfirmation = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('Error', {
          attrs: {
            "size": "small",
            "error": _vm.commonError
          }
        }), _c('button', {
          staticClass: "forum-button new-password__submit-btn",
          class: {
            'forum-button_loading': _vm.submitting
          },
          attrs: {
            "title": _vm.getTranslate.forum.newPasswordSubmitBtn,
            "type": "submit",
            "disabled": invalid || _vm.submitting
          }
        }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.newPasswordSubmitBtn) + " ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }