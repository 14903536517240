var render = function () {
  var _vm$getSelf2, _vm$getPost$data, _vm$getSelf3, _vm$getSelf4, _vm$getPost$data2, _vm$getSelf5, _vm$getPost$data3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('spinner-container', {
    attrs: {
      "loading": !_vm.getTranslate || _vm.getPost.loading || _vm.getDiscussionList.data.loading
    }
  }, [_vm.getTranslate.forum && _vm.getPost.data && _vm.getPost.data.discussion ? _c('div', {
    staticClass: "view-post"
  }, [_c('div', {
    staticClass: "view-post__main"
  }, [_c('breadcrumbs', {
    attrs: {
      "itemList": [{
        label: _vm.getTranslate.forum.forum,
        route: _vm.Routes.ForumTopicList
      }, {
        label: _vm.getPost.data.discussion.title,
        route: {
          name: _vm.RouterName.ForumTopicView,
          params: {
            topicId: _vm.getPost.data.discussion.id
          }
        }
      }, {
        label: _vm.getPost.data.title,
        route: ''
      }],
      "wrapperClass": "topic-list__breadcrumbs"
    }
  }), _c('h1', {
    staticClass: "view-post__title"
  }, [_vm._v(" " + _vm._s(_vm.getPost.data.title) + " ")]), _c('div', {
    staticClass: "view-post__content"
  }, [_c('div', {
    staticClass: "output ql-snow"
  }, [_c('div', {
    staticClass: "ql-editor",
    domProps: {
      "innerHTML": _vm._s(_vm.getPost.data.content)
    }
  })]), _c('button', {
    staticClass: "view-post__comment-btn prevent-button-styles",
    on: {
      "click": _vm.handleCreateNewComment
    }
  }, [_c('svg', {
    class: 'view-post__comment-icon',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/comment.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z",
      "fill": "currentColor"
    }
  })]), _vm._v(" " + _vm._s(_vm.getTranslate.forum.btnComment) + " ")]), _vm.getCommentList.length ? _c('div', {
    staticClass: "view-post__comment-block"
  }, _vm._l(_vm.getCommentList, function (_ref) {
    var _vm$getSelf;

    var author = _ref.author,
        content = _ref.content,
        id = _ref.id;
    return _c('div', {
      key: id,
      staticClass: "comment",
      attrs: {
        "id": "comment-".concat(id)
      }
    }, [_c('div', {
      staticClass: "comment__row"
    }, [_c('div', {
      staticClass: "comment__username"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: _vm.RouterName.ForumCurrentParticipant,
          params: {
            userId: author.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(author.name) + " ")])], 1), author.id === ((_vm$getSelf = _vm.getSelf) === null || _vm$getSelf === void 0 ? void 0 : _vm$getSelf.id) || _vm.permissions.deleteComments ? _c('button', {
      staticClass: "comment__delete-btn prevent-button-styles",
      attrs: {
        "title": _vm.getTranslate.forum.deleteComment
      },
      on: {
        "click": function click($event) {
          return _vm.handleDeleteComment(id);
        }
      }
    }, [_c('svg', {
      attrs: {
        "height": "512",
        "viewBox": "0 0 386.667 386.667",
        "width": "512",
        "xmlns": "http://www.w3.org/2000/svg",
        "svg-inline": '',
        "src": '@/shared/Images/Forum/cross.svg'
      }
    }, [_c('path', {
      attrs: {
        "d": "M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z",
        "fill": "currentColor"
      }
    })])]) : _vm._e()]), _c('div', {
      domProps: {
        "innerHTML": _vm._s(content)
      }
    }), _c('button', {
      staticClass: "comment__answer-btn prevent-button-styles",
      attrs: {
        "data-id": id,
        "data-label": author.name
      },
      on: {
        "click": _vm.handleCreateAnswer
      }
    }, [_c('svg', {
      class: 'view-post__comment-icon',
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "viewBox": "0 0 24 24",
        "svg-inline": '',
        "src": '@/shared/Images/Forum/comment.svg'
      }
    }, [_c('path', {
      attrs: {
        "d": "M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z",
        "fill": "currentColor"
      }
    })]), _vm._v(" " + _vm._s(_vm.getTranslate.forum.btnAnswer) + " ")])]);
  }), 0) : _vm._e()])], 1), _c('div', {
    staticClass: "view-post__sidebar"
  }, [_c('div', {
    staticClass: "sidebar"
  }, [_vm.permissions.updatePosts || (_vm$getSelf2 = _vm.getSelf) !== null && _vm$getSelf2 !== void 0 && _vm$getSelf2.id && ((_vm$getPost$data = _vm.getPost.data) === null || _vm$getPost$data === void 0 ? void 0 : _vm$getPost$data.creatorId) === ((_vm$getSelf3 = _vm.getSelf) === null || _vm$getSelf3 === void 0 ? void 0 : _vm$getSelf3.id) ? _c('router-link', {
    staticClass: "forum-button m-b_1 sidebar__delete-link",
    attrs: {
      "to": {
        name: _vm.RouterName.ForumPostEdit,
        params: {
          topicId: _vm.getPost.data.discussion.id,
          postId: _vm.getPost.data.id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.editPost))]) : _vm._e(), _vm.permissions.deletePosts || (_vm$getSelf4 = _vm.getSelf) !== null && _vm$getSelf4 !== void 0 && _vm$getSelf4.id && ((_vm$getPost$data2 = _vm.getPost.data) === null || _vm$getPost$data2 === void 0 ? void 0 : _vm$getPost$data2.creatorId) === ((_vm$getSelf5 = _vm.getSelf) === null || _vm$getSelf5 === void 0 ? void 0 : _vm$getSelf5.id) ? _c('button', {
    staticClass: "warning-button m-b_1",
    class: {
      'warning-button_loading': _vm.getPost.deletePostLoading
    },
    attrs: {
      "disabled": _vm.getPost.deletePostLoading
    },
    on: {
      "click": _vm.handleDeletePost
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.deletePost))]) : _vm._e(), _c('button', {
    staticClass: "forum-button",
    class: {
      'forum-button_loading': _vm.getPost.subscribePostLoading
    },
    attrs: {
      "disabled": _vm.getPost.data.isSubscribed || _vm.getPost.subscribePostLoading
    },
    on: {
      "click": function click($event) {
        return _vm.actionPostSubscribe(_vm.getPost.data.id);
      }
    }
  }, [_vm._v(_vm._s(_vm.getPost.data.isSubscribed ? _vm.getTranslate.forum.buttonYouAreFollowPost : _vm.getTranslate.forum.buttonFollowPost))]), _c('div', {
    staticClass: "sidebar__statistic-block"
  }, [_c('div', {
    staticClass: "sidebar__row"
  }, [_c('svg', {
    class: 'sidebar__icon',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24",
      "width": "24",
      "viewBox": "0 0 511.999 511.999",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/eye.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M508.129 245.942c-1.155-1.278-28.809-31.661-73.431-62.307C375.034 142.659 313.241 121 255.999 121c-57.241 0-119.034 21.659-178.698 62.635-44.622 30.646-72.275 61.029-73.43 62.307-5.162 5.712-5.162 14.404 0 20.115 1.155 1.278 28.809 31.661 73.431 62.307C136.965 369.341 198.759 391 255.999 391c57.241 0 119.034-21.659 178.698-62.635 44.622-30.646 72.275-61.029 73.431-62.307 5.161-5.712 5.161-14.404.001-20.116zM145.484 333.44c-40.404-19.392-78.937-47.81-109.449-77.434 17.675-17.131 57.684-52.53 109.471-77.478C130.074 200.472 121 227.195 121 256c-.001 28.791 9.064 55.502 24.484 77.44zM255.999 361c-57.848 0-105-47.06-105-105 0-57.897 47.103-105 105-105 57.854 0 105 47.066 105 105 .001 57.897-47.102 105-105 105zm110.495-27.528C381.926 311.528 391 284.805 391 256c0-28.791-9.065-55.502-24.484-77.44 40.404 19.392 78.937 47.81 109.449 77.434-17.674 17.131-57.684 52.53-109.471 77.478z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M255.999 181c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75c.001-41.355-33.644-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.186 45-45 45z",
      "fill": "currentColor"
    }
  })]), _vm._v(" " + _vm._s(_vm.getTranslate.forum.viewCountLabel) + " " + _vm._s(_vm.getPost.data.views) + " ")]), _c('div', {
    staticClass: "sidebar__row"
  }, [_c('svg', {
    class: 'sidebar__icon',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/comment.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z",
      "fill": "currentColor"
    }
  })]), _vm._v(" " + _vm._s(_vm.getTranslate.forum.commentCountLabel) + " " + _vm._s(_vm.getPost.data.comments) + " ")]), _c('div', {
    staticClass: "sidebar__row"
  }, [_c('svg', {
    class: 'sidebar__icon',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/comment.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z",
      "fill": "currentColor"
    }
  })]), _vm._v(" " + _vm._s(_vm.getTranslate.forum.answerCountLabel) + " " + _vm._s(0) + " ")])]), _c('div', {
    staticClass: "sidebar__statistic-block"
  }, [_c('div', {
    staticClass: "sidebar__row"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.topicList) + " ")]), _vm._l(_vm.getDiscussionList.data.list.slice(0, 3), function (_ref2) {
    var id = _ref2.id,
        title = _ref2.title;
    return _c('router-link', {
      key: id,
      staticClass: "sidebar__row",
      attrs: {
        "to": {
          name: _vm.RouterName.ForumTopicView,
          params: {
            topicId: id
          }
        }
      }
    }, [_vm._v(_vm._s(title))]);
  }), _c('router-link', {
    staticClass: "sidebar__row sidebar__show-all-topic",
    attrs: {
      "to": _vm.Routes.ForumTopicList
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.viewAllTopicList))])], 2)], 1)])]) : _vm._e(), !_vm.getPost.loading && !((_vm$getPost$data3 = _vm.getPost.data) !== null && _vm$getPost$data3 !== void 0 && _vm$getPost$data3.id) ? _c('div', [_c('ErrorComponent', {
    attrs: {
      "title": "404",
      "content": _vm.getTranslate.common.pageNotFound
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }