var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-documentation page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "class-name": "desktop__line"
    }
  }), _c('div', {
    staticClass: "desktop-documentation__inner-content"
  }, _vm._l(_vm.menuPages, function (_ref) {
    var id = _ref.id,
        title = _ref.title,
        images = _ref.images;
    return _c('div', {
      key: id
    }, [title && !!images.length ? _c('h4', {
      staticClass: "main-title main-title_left block-title",
      attrs: {
        "id": title
      }
    }, [_vm._v(_vm._s(title))]) : _vm._e(), !!images.length ? _c('div', {
      staticClass: "desktop-documentation__img-block"
    }, _vm._l(images, function (image, index) {
      return _c('div', {
        key: image,
        staticClass: "desktop-documentation__img-wrapper"
      }, [_c('img', {
        staticClass: "desktop-documentation__img",
        attrs: {
          "src": image,
          "alt": ""
        },
        on: {
          "click": function click($event) {
            return _vm.handleDocumentClick(title, index);
          }
        }
      })]);
    }), 0) : _vm._e()]);
  }), 0), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openedCarouselTitle,
      expression: "openedCarouselTitle"
    }],
    staticClass: "desktop-documentation__full-document-wrapper"
  }, [_c('div', {
    staticClass: "desktop-documentation__carousel-wrapper"
  }, [_c('button', {
    staticClass: "desktop-documentation__carousel-close-btn",
    on: {
      "click": _vm.handleReset
    }
  }, [_c('svg', {
    class: 'desktop-documentation__carousel-close-icon',
    attrs: {
      "height": "512",
      "viewBox": "0 0 386.667 386.667",
      "width": "512",
      "xmlns": "http://www.w3.org/2000/svg",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/cross.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z",
      "fill": "currentColor"
    }
  })])]), _c('slider', {
    attrs: {
      "initialSlide": _vm.startIndex,
      "images": _vm.getImages()
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }