var render = function () {
  var _vm$getUserList$meta;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.getTranslate.forum ? _c('div', {
    staticClass: "participants"
  }, [_c('Breadcrumbs', {
    attrs: {
      "itemList": [{
        label: _vm.getTranslate.forum.forum,
        route: _vm.Routes.ForumTopicList
      }, {
        label: _vm.getTranslate.forum.participants,
        route: ''
      }]
    }
  }), _c('spinner-container', {
    attrs: {
      "loading": _vm.getUserListLoading
    }
  }, [_c('div', {
    staticClass: "participants__content"
  }, _vm._l(_vm.getUserList.data, function (_ref, index) {
    var surname = _ref.surname,
        middlename = _ref.middlename,
        job = _ref.job,
        appointment = _ref.appointment,
        id = _ref.id,
        name = _ref.name;
    return _c('div', {
      key: id,
      staticClass: "participants__item"
    }, [!name && !middlename && !surname && !job && !appointment ? _c('router-link', {
      staticClass: "participants__content-item",
      attrs: {
        "to": {
          name: _vm.RouterName.ForumCurrentParticipant,
          params: {
            userId: id
          }
        }
      }
    }, [_vm._v(_vm._s(++index) + ". " + _vm._s(_vm.getTranslate.forum.emptyProfile))]) : _c('div', {
      staticClass: "participants__content-item"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: _vm.RouterName.ForumCurrentParticipant,
          params: {
            userId: id
          }
        }
      }
    }, [_vm._v(_vm._s(index + 1) + ". " + _vm._s(name || middlename || surname ? _vm.getUserName(surname, name, middlename) : _vm.getTranslate.forum.emptyProfile))]), job || appointment ? _c('div', {
      staticClass: "participants__job-info"
    }, [_vm._v(_vm._s(job && appointment ? " ".concat(job, " \u25CF ") : job) + " " + _vm._s(appointment))]) : _vm._e()], 1)], 1);
  }), 0), _c('pagination', {
    attrs: {
      "per-page": 10,
      "records": ((_vm$getUserList$meta = _vm.getUserList.meta) === null || _vm$getUserList$meta === void 0 ? void 0 : _vm$getUserList$meta.total) || 0
    },
    on: {
      "paginate": _vm.handlePaginate
    },
    model: {
      value: _vm.getUserList.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.getUserList.meta, "current_page", $$v);
      },
      expression: "getUserList.meta.current_page"
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }