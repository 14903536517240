var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.getTranslate.forum ? _c('div', [_c('h3', {
    staticClass: "sign-in-modal__title"
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.signInTitle))]), _c('ValidationObserver', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('form', {
          staticClass: "sign-in-modal__form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitSignIn.apply(null, arguments);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }],
                staticClass: "sign-in-modal__input",
                class: {
                  'sign-in-modal__input_error': errors[0]
                },
                attrs: {
                  "type": "email",
                  "placeholder": _vm.getTranslate.forum.email
                },
                domProps: {
                  "value": _vm.email
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }
                    _vm.email = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": "password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }],
                staticClass: "sign-in-modal__input",
                class: {
                  'sign-in-modal__input_error': errors[0]
                },
                attrs: {
                  "type": "password",
                  "placeholder": _vm.getTranslate.forum.pass
                },
                domProps: {
                  "value": _vm.password
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }
                    _vm.password = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('Error', {
          attrs: {
            "size": "small",
            "error": _vm.commonError
          }
        }), _c('button', {
          staticClass: "forum-button sign-in-modal__submit-btn",
          class: {
            'forum-button_loading': _vm.submit
          },
          attrs: {
            "type": "submit",
            "disabled": invalid || _vm.submit
          }
        }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.signIn) + " ")])], 1)];
      }
    }], null, false, 3326790789)
  }), _c('div', {
    staticClass: "sign-in-modal__helper-text"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.signInForgotTitle) + " "), _c('router-link', {
    staticClass: "sign-in-modal__redirect-link",
    attrs: {
      "to": _vm.Routes.ForumForgotPassword
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.pass))]), _vm._v(" ? ")], 1), _c('div', {
    staticClass: "sign-in-modal__helper-text"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.signInCreateAccount) + " "), _c('router-link', {
    staticClass: "sign-in-modal__redirect-link",
    attrs: {
      "to": _vm.Routes.ForumSignUp
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.signUp))])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }