var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fixators"
  }, [_c('div', {
    staticClass: "fixators__content page-content fixators__content_center"
  }, [_c('iframe', {
    staticClass: "capture",
    attrs: {
      "width": "560",
      "height": "515",
      "src": "https://www.youtube.com/embed/mY20Oz80zho",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      "allowfullscreen": ""
    }
  }), _c('h3', {
    staticClass: "main-title"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.mainTitle))]), _c('div', {
    staticClass: "sub-title"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.mainSubTitle))]), _c('img', {
    staticClass: "capture fixators__skelet-img",
    attrs: {
      "alt": "",
      "src": _vm.Images.skelet
    }
  }), _c('div', {
    staticClass: "trio"
  }, [_c('div', {
    staticClass: "trio__img-wrapper"
  }, [_c('img', {
    staticClass: "capture",
    attrs: {
      "alt": "",
      "src": _vm.Images.firstRent
    }
  }), _c('span', {
    staticClass: "sub-title"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.imageSubTitle1))])]), _c('div', {
    staticClass: "trio__img-wrapper"
  }, [_c('img', {
    staticClass: "capture",
    attrs: {
      "alt": "",
      "src": _vm.Images.secondRent
    }
  }), _c('span', {
    staticClass: "sub-title"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.imageSubTitle2))])]), _c('div', {
    staticClass: "trio__img-wrapper"
  }, [_c('img', {
    staticClass: "capture",
    attrs: {
      "alt": "",
      "src": _vm.Images.thirdRent
    }
  }), _c('span', {
    staticClass: "sub-title"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.imageSubTitle3))])])]), _c('img', {
    staticClass: "capture middle reverse",
    attrs: {
      "src": _vm.Images.fixGold
    }
  })]), _c('div', {
    staticClass: "blue-block"
  }, [_c('div', {
    staticClass: "blue-block__column"
  }, [_c('div', {
    staticClass: "white-text",
    domProps: {
      "innerHTML": _vm._s(_vm.getTranslate.clamps.mini)
    }
  }), _c('br'), _c('div', {
    staticClass: "white-text",
    domProps: {
      "innerHTML": _vm._s(_vm.getTranslate.clamps.constructive)
    }
  })]), _c('div', {
    staticClass: "blue-block__column"
  }, [_c('div', {
    staticClass: "white-text",
    domProps: {
      "innerHTML": _vm._s(_vm.getTranslate.clamps.bio)
    }
  }), _c('br'), _c('div', {
    staticClass: "white-text",
    domProps: {
      "innerHTML": _vm._s(_vm.getTranslate.clamps.osteo)
    }
  })])]), _vm._l(_vm.menuChildren, function (menu) {
    return _c('div', {
      key: menu.id,
      staticClass: "fixators__content page-content"
    }, [_c('div', {
      staticClass: "fixators__row"
    }, [_c('h4', {
      staticClass: "main-title main-title_left main-title_remove-offset",
      attrs: {
        "id": menu.name
      }
    }, [_vm._v(_vm._s(menu.name))]), !!menu.catalog_link ? _c('button', {
      staticClass: "download-catalog-btn",
      on: {
        "click": function click($event) {
          return _vm.buttonClick(menu.catalog_link);
        }
      }
    }, [_vm._v(_vm._s(_vm.getTranslate.clamps.downloadCatalog))]) : _vm._e()]), _c('broken-line', {
      attrs: {
        "class-name": "desktop__line"
      }
    }), _c('div', {
      staticClass: "fixators__inner-content"
    }, _vm._l(_vm.getMenuPages(menu.id), function (item) {
      return _c('div', {
        key: item.id,
        staticClass: "fixators__preview"
      }, [_c('Instrument', {
        attrs: {
          "link": {
            name: _vm.RouterName.CurrentInstrument,
            params: {
              id: item.id
            }
          },
          "instrument": item
        }
      })], 1);
    }), 0)], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }