var render = function () {
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-loader",
    class: (_class = {
      'spinner-loader_load': _vm.loading
    }, _class["spinner-loader_size_".concat(_vm.size)] = !!_vm.size, _class[_vm.className] = _vm.className, _class)
  }, [_c('span', {
    staticClass: "spinner-loader__bounce1"
  }), _c('span', {
    staticClass: "spinner-loader__bounce2"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }