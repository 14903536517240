var render = function () {
  var _vm$page$images, _vm$page$images2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about"
  }, [_c('div', {
    staticClass: "mobile-wrapper"
  }, [_vm.getPageTitle(_vm.$router.currentRoute.path) && _vm.page ? _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h2', {
    staticClass: "main-title main-title_light main-title_left p-l_standard",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1) : _vm._e(), !!_vm.page && _vm.getTranslate ? _c('div', {
    staticClass: "about__content"
  }, [_c('div', {
    staticClass: "light-title m-t_1"
  }, [_vm._v(_vm._s(_vm.page.title))]), !!((_vm$page$images = _vm.page.images) !== null && _vm$page$images !== void 0 && _vm$page$images[0]) ? _c('img', {
    staticClass: "capture",
    attrs: {
      "src": (_vm$page$images2 = _vm.page.images) === null || _vm$page$images2 === void 0 ? void 0 : _vm$page$images2[0]
    }
  }) : _vm._e(), _c('div', {
    staticClass: "t-a_i m-t_1",
    domProps: {
      "innerHTML": _vm._s(_vm.page.content)
    }
  }), _c('div', {
    staticClass: "resident"
  }, [_c('img', {
    staticClass: "ros",
    attrs: {
      "src": _vm.Images.eco,
      "alt": "РОСОЭЗ"
    }
  }), _c('div', {
    staticClass: "text"
  }, [_c('span', {
    staticClass: "special",
    attrs: {
      "id": _vm.getTranslate.about.tvt
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.tvt) + " " + _vm._s(_vm.getTranslate.about.tvtBold))])])])]) : _vm._e(), _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h3', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.about.contacts
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.contacts))]), _c('broken-line')], 1), _c('div', {
    staticClass: "about__content"
  }, [_c('div', {
    staticClass: "light-title m-t_1 m-b_1",
    attrs: {
      "id": _vm.getTranslate.about.salesDepartment
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.salesDepartment))]), _c('a', {
    staticClass: "simple",
    attrs: {
      "href": "tel:".concat(_vm.getTranslate.about.salesPhone)
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.salesPhone))]), _c('a', {
    staticClass: "simple",
    attrs: {
      "href": "mailto:".concat(_vm.getTranslate.about.salesMail)
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.salesMail))]), _c('div', {
    staticClass: "light-title m-t_1 m-b_1",
    attrs: {
      "id": _vm.getTranslate.about.productionDepartment
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.productionDepartment))]), _c('a', {
    staticClass: "simple",
    attrs: {
      "href": "tel:".concat(_vm.getTranslate.about.productionPhone)
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.productionPhone))]), _c('a', {
    staticClass: "simple",
    attrs: {
      "href": "mailto:".concat(_vm.getTranslate.about.productionMail)
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.productionMail))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }